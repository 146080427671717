@import '../../styles/colors';

.card-pro {
    border-radius: 5px;
    padding: 0.5em;
    margin-top: 8em!important;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    background-color: white;
    position: relative;

    .fa, .fas {
        color: $background;
    }
}
.card-pro-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -10em!important;
}
.photo {
    border-radius: 12em;
    width: 12em!important;
    height: 12em!important;
    border: 4px solid $pink;
    display: flex;
    justify-content: center;
    overflow: hidden;

    img {
        display: inline;
        margin: 0;
        height: 100%;
    }
}
.btn-delete-pro, .btn-edit-pro {
    position: relative;
    display: flex;
    width: 3em !important;
    height: 3em !important;
    align-items: center;
    justify-content: center;
    border-radius: 3em;
    padding: 0.35em 0.6em;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;

    i {
        color: #fff !important;
    }

    &:hover {
        background-color: $background-grey;
    }
}
.btn-delete-pro {
    background-color: $red;
    &:hover i {
        color: $red !important;
    }
}
.btn-edit-pro {
    background-color: $text-grey;
    &:hover i {
        color: $text-grey !important;
    }
}
.item-title {
    font-size: 12px;
    color: $background;
    font-weight: bold;
    margin-bottom: 0.2em;
}
.student-icon {
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    padding: 1em 0.5em;
    font-size: 1.2em;
    margin: 0;
    position: absolute;
    width: fit-content !important;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 15%;
    top: -5%;
}
@media only screen and (max-width: 767px) {
    .card-pro {
        max-width: 90vw;
    }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
    .card-pro {
        max-width: 40vw;
    }
}
@media screen and (min-width: 1200px) {
    .card-pro {
        max-width: 25vw;
    }
}